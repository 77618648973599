<template>
      <!-- 申请发票个人抬头 -->
    <div class="person-look">
      <div class="person-header">
        <p><strong>发票信息</strong></p>
      </div>
      <div class="mb-40"></div>
      <div class="person-look-address" >
        <ul>
          <li><span>发票抬头:</span><strong>某某某</strong></li>
          <li><span>发票金额:</span><strong>4000元</strong></li>
          <li><span>发票类型:</span><strong>增值税普通发票</strong></li>
          <li><span>发票介质:</span><el-radio-group v-model="personRadio">
            <el-radio :label="1">电子发票<b class="r ml-10">(推荐)</b></el-radio>
            <el-radio :label="2">纸质发票</el-radio>
            </el-radio-group></li>
          <div class="person-look-address-electronic">
            <p v-show="personRadio==1">电子发票将在24小时内开具完成，开具后，可在发票详情页下载。</p>
            <AddAddress v-show="personRadio==2"></AddAddress>
          </div>

          <!-- 当有多个地址时 -->  
          <div class="person-look-address-button">
            <div class="mb-40"></div>
            <div class="person-look-address-button-center">
              <el-button type="button" class="person-button1">确认申请发票</el-button>
              <div class="mb-20"></div>
              <el-button type="text" class="f-12 h-b5">取消申请</el-button>
            </div>
          </div>
        </ul>
      </div>
      <!-- 申请发票企业抬头 -->
      <div class="firm-look" v-show="true">
      <div class="mt-40"></div>
      <ul>
        <li><span class="f-14 h-57">发票抬头:</span><strong>北京元美传媒科技有限责任公司</strong></li>
        <li><span class="f-14 h-57">发票税号:</span><strong>2354658768753432</strong></li>
        <li><span class="f-14 h-57">发票内容:</span><strong>服务费</strong></li>
        <li><span class="f-14 h-57">发票金额:</span><strong>4000元</strong></li>
        <li><span class="f-14 h-57">公司地址:</span><strong></strong></li>
        <li><span class="f-14 h-57">公司电话:</span><strong></strong></li>
        <li><span class="f-14 h-57">公司开户行:</span><strong></strong></li>
        <li><span class="f-14 h-57">开户行账号:</span><strong></strong></li>
        <li><span class="f-14 h-57">发票类型:</span>
        <strong><el-radio-group v-model="firmRadioType">
          <el-radio :label="1">增值税普通发票</el-radio>
          <el-radio :label="2">增值税专用发票</el-radio>
          </el-radio-group></strong></li>
        <li><span class="f-14 h-57">发票介质:</span><strong><el-radio-group v-model="firmRadioMedium">
          <el-radio :label="1">电子发票<b class="r ml-10">(推荐)</b></el-radio>
          <el-radio :label="2">纸质发票</el-radio>
          </el-radio-group></strong>
        </li>
          <div class="applyInvoice-electronic" v-show="firmRadioMedium==1">
            电子发票将在24小时内开具完成，开具后，可在发票详情页下载。
          </div>
          <AddAddress v-show="firmRadioMedium==2"></AddAddress>
      </ul>
      <div class="mb-40"></div>
      <div class="person-look-address-button-center">
              <el-button type="button" class="person-button1">确认申请发票</el-button>
              <div class="mb-20"></div>
              <el-button type="text" class="f-12 h-b5">取消申请</el-button>
      </div>
      </div>
    </div>
</template>

<script>
import AddAddress from "@/views/layout/modules/hirer/AddAddress"
export default {
  components:{
    AddAddress,
  },
  data(){
    return{
      /* 个人抬头的单选框 */
      personRadio:1,
      firmRadioType:1, //企业发票类型
      firmRadioMedium:1, //企业发票介质
    }
  }
}
</script>

<style lang="less" scoped>
/* 申请发票 个人抬头 */
.person-look{
  background-color: #fff;
  .person-look-address{
    width: 860px;
    margin: 0 auto;
    ul{
      & li:first-of-type span::before{
        content: "*";
        color: #e4007f;
      }
      li{
        span{
          display: inline-block;
          width: 75px;
          text-align: right;
          margin-right: 20px;
          color: #575757;
        }
        strong{
          font-weight: 400;
        }
        /deep/.el-radio{
          color: #000;
        }
        margin-bottom: 20px;
      }
      .person-look-address-border{
        width: 860px;
        .person-look-address-border-center{
          display: flex;
          width: 905px;
          flex-wrap: wrap;
          &>div{
            margin-bottom: 40px;
            width: 260px;
            height: 130px;
            background-color: #f4f4f4;
            margin-right: 40px;
          }
          .person-look-address-border1{
             background-color: #fff;
            border: 1px solid #00ace6;
          }
          .person-look-address-border1,
          .person-look-address-border2{
            .person-look-address-border1-center{
              width: 220px;
              margin: 0 auto;
              .person-look-address-border1-header{
                display: flex;
                justify-content: space-between;
                margin-top: 22px;
                height: 24px;
                border-bottom: 1px solid #b5b5b5;
              }
              .person-look-address-border1-bottom{
                p{
                  margin-top: 5px;
                  line-height: 20px;
                }
              }
            }
            .person-look-address-border2-center{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .person-look-address-border2-center-border{
                  width: 72px;
                  height: 40px;
                  text-align: center;
                  cursor: pointer;
                }
              }
          }
        }
      }
      /* 电子发票 */
      .person-look-address-electronic{
        p{
          margin-left: 95px;
          font-size: 12px;
          color: #575757;
        }
      }
    }
    .person-look-address-button{
      .person-look-address-button-center{
        width: 200px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 40px;
      
      }
    }
  }
}
/* 申请发票企业 抬头 */
.firm-look{
  width: 860px;
  margin: 0 auto;
  padding-bottom: 40px;
  ul{
    li{
      height: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      span{
        display: inline-block;
        width: 75px;
        text-align: right;
      }
      strong{
        font-size: 16px;
        margin-left: 20px;
      }
    }
    & li:first-of-type span::before,
    & li:nth-child(2) span::before{
      content: "*";
      color: #e4007f;
      margin-right: 3px;
      display: inline-block;
    }
  }
  .applyInvoice-electronic{
    margin-left: 95px;
    font-size: 12px;
    color: #575757;
  }
  .firm-li{
    display: flex;
    .applyInvoice-electronic,
    .applyInvoice-paper{
      margin-left: 95px;
      font-size: 12px;
      color: #575757;
    }
  }
}
/* 公用的button */
.person-look-address-button-center{
  width: 200px;
  text-align: center;
  margin: 0 auto;
}
</style>